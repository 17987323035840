import { Container, Navbar, Text } from "@nextui-org/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

function Layout() {
  const location = useLocation();
  const currentRoute = location.pathname;
  let pageName;
  if (currentRoute === "/") {
    pageName = "My Teams";
  } else if (currentRoute === "/my-leagues") {
    pageName = "My Leagues";
  } else if (currentRoute === "/search-league") {
    pageName = "Search a League";
  }
  return (
    <Container>
      <Navbar isCompact isBordered variant="sticky">
        <Navbar.Brand>
          <Text b color="inherit">
            <h1>SleeperHub</h1>
          </Text>
        </Navbar.Brand>
        <Navbar.Content hideIn="xs" align="center">
          <Text b>{pageName}</Text>
        </Navbar.Content>
        <Navbar.Content hideIn="xs" variant="underline">
          <Navbar.Link as={RouterLink} to="/">
            My Teams
          </Navbar.Link>
          <Navbar.Link as={RouterLink} to="/my-leagues">
            My Leagues
          </Navbar.Link>
          <Navbar.Link as={RouterLink} to="/search-league">
            Search a League
          </Navbar.Link>
        </Navbar.Content>
      </Navbar>
    </Container>
  );
}

export default Layout;
