import React from "react";
import UserIdForLeagues from "./UserIdForLeagues";
import UserLeagues from "./UserLeagues";
import LeagueRosters from "./LeagueRosters";
import { Row, Col, Container } from "@nextui-org/react";

function MyTeams({ userId, handleUserIdChange }) {
  return (
    <Container>
      <Row
        justify="center"
        align="middle"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <UserIdForLeagues
          userId={userId}
          handleUserIdChange={handleUserIdChange}
        />
      </Row>
      <Row justify="center">
        {userId ? (
          <Col span={12}>
            <UserLeagues userId={userId}>
              {(userLeagues) => (
                <LeagueRosters userLeagues={userLeagues} userId={userId} />
              )}
            </UserLeagues>
          </Col>
        ) : (
          <p>Please enter your username or user ID above.</p>
        )}
      </Row>
    </Container>
  );
}

export default MyTeams;
