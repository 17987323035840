import { Container, Text, Image, Card, Grid } from "@nextui-org/react";

function LeagueDetails({ league, leagueDetails }) {
  const rosterPositions = league.roster_positions.reduce((acc, pos) => {
    if (acc[pos]) {
      acc[pos]++;
    } else {
      acc[pos] = 1;
    }
    return acc;
  }, {});
  const benchCount = league.roster_positions.filter(
    (pos) => pos === "BN"
  ).length;

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Text
        h4
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        Roster Settings:
      </Text>
      <Text
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {rosterPositions["QB"]} QB, {rosterPositions["RB"]} RB,
        {rosterPositions["WR"]} WR, {rosterPositions["TE"]} TE,{" "}
        {rosterPositions["FLEX"]} FLEX, {rosterPositions["K"]} K,{" "}
        {rosterPositions["DEF"]} D/ST, {benchCount} Bench
      </Text>
      <Grid.Container justify="center" gap={2}>
        {leagueDetails.map((user) => (
          <Grid xs={12} sm={6} md={4} lg={3} key={user.user_id}>
            <Card>
              <Card.Header
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text h2>{user.metadata.team_name}</Text>
                <Text h3>{user.display_name}</Text>
              </Card.Header>
              <Card.Body>
                {user.metadata.avatar ? (
                  <Image src={user.metadata.avatar} alt="avatar" />
                ) : (
                  <Image src="/images/sleeper.jpg" alt="placeholder image" />
                )}
              </Card.Body>
            </Card>
          </Grid>
        ))}
      </Grid.Container>
    </Container>
  );
}

export default LeagueDetails;
