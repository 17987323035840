// This is the implementation of getting player info from Express route instead of
// straight from firestore. This is slower, but more secure
import React, { useState, useEffect } from "react";
import { Button, Loading } from "@nextui-org/react";

const Player = ({ playerId }) => {
  const [playerName, setPlayerName] = useState("");
  const [playerPos, setPlayerPos] = useState("");
  const [, setPlayerIds] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const playerElements = document.querySelectorAll(".player");
    const ids = Array.from(playerElements).map((element) => {
      return element.getAttribute("player_id");
    });
    setPlayerIds(ids);
    async function getPlayerInfo() {
      try {
        const response = await fetch(`/api/players/${playerId}`);
        const data = await response.json();
        setPlayerName(data.name);
        setPlayerPos(data.position);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching player info:", error);
      }
    }
    if (/^[a-zA-Z]+$/.test(playerId)) {
      // If the playerId is a string of letters, set the player name to the playerId
      setPlayerName(playerId);
      setPlayerPos("DEF");
      setLoading(false);
    } else {
      getPlayerInfo();
    }
  }, [playerId]);

  return (
    <div className="player" player_id={playerId}>
      {loading ? (
        <Button
          disabled
          auto
          bordered
          color="neutral"
          style={{ width: "100%" }}
        >
          <Loading type="points" color="currentColor" size="sm" />
        </Button>
      ) : (
        <div>
          <Button.Group style={{ width: "100%" }}>
            <Button
              onClick={() => {
                window.open(
                  `https://www.google.com/search?q=${encodeURIComponent(
                    playerName
                  )}`,
                  "_blank"
                );
              }}
              variant="text"
              style={{
                backgroundColor:
                  playerPos === "QB"
                    ? "#e7436f"
                    : playerPos === "RB"
                    ? "#62cbb8"
                    : playerPos === "WR"
                    ? "#6ca5f8"
                    : playerPos === "TE"
                    ? "#f2b167"
                    : playerPos === "K"
                    ? "#b16af7"
                    : playerPos === "DEF"
                    ? "#b36447"
                    : "",
                color: "white",
                width: "20%",
                minWidth: "50px", // add this line to set a minimum width for the button
              }}
            >
              {playerPos}
            </Button>
            <Button
              onClick={() => {
                window.open(
                  `https://www.google.com/search?q=${encodeURIComponent(
                    playerName
                  )}`,
                  "_blank"
                );
              }}
              variant="text"
              style={{
                backgroundColor: "#3A4E68",
                color: "white",
                width: "80%",
                minWidth: "150px", // add this line to set a minimum width for the button
              }}
            >
              {playerName}
            </Button>
          </Button.Group>
        </div>
      )}
    </div>
  );
};

export default Player;
