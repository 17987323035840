import React, { useState } from "react";
import "./styles/App.css";
import MyTeams from "./Components/MyTeams";
import MyLeagues from "./Components/MyLeagues";
import SearchLeague from "./Components/SearchLeague";
import Layout from "./Components/Layout";
import UserDetails from "./Components/UserDetails";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  const [userId, setUserId] = useState("");
  const [leagueId, setLeagueId] = useState("");

  const handleUserIdChange = (value) => {
    if (value.trim() === "") {
      // If value is empty, clear user ID
      setUserId("");
    } else {
      // Otherwise, fetch user ID or set as input value
      fetch(`/api/user/${value}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.user_id) {
            setUserId(data.user_id);
          } else {
            setUserId(value);
          }
        })
        .catch((error) => {
          console.log("Error fetching user ID:", error);
        });
    }
  };

  const handleLeagueIdChange = (value) => {
    if (value.trim() === "") {
      setLeagueId("");
    } else {
      setLeagueId(value);
    }
  };

  return (
    <Router>
      <Layout />
      <Routes>
        <Route
          path="/"
          element={
            <MyTeams userId={userId} handleUserIdChange={handleUserIdChange} />
          }
        />
        <Route
          path="/my-leagues"
          element={
            <MyLeagues
              userId={userId}
              handleUserIdChange={handleUserIdChange}
            />
          }
        />
        <Route
          path="/search-league"
          element={
            <SearchLeague
              leagueId={leagueId}
              handleLeagueIdChange={handleLeagueIdChange}
            />
          }
        />
        <Route path="/user/:id" element={<UserDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
