import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LeagueName from "./LeagueName";
import UserIdForLeagues from "./UserIdForLeagues";
import { Row, Container, Card, Button } from "@nextui-org/react";

function MyLeagues({ userId, handleUserIdChange }) {
  const [userLeagues, setUserLeagues] = useState([]);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await fetch(`/api/user-leagues/${userId}`);
        const leagues = await response.json();

        // For each league, fetch the users and add them to the league object
        const leaguesWithUsers = await Promise.all(
          leagues.map(async (league) => {
            const response = await fetch(
              `/api/league/${league.league_id}/users`
            );
            const users = await response.json();
            return { ...league, users };
          })
        );

        setUserLeagues(leaguesWithUsers);
      } catch (error) {
        console.error("Error fetching user leagues:", error);
      }
    };

    if (userId) {
      fetchLeagues();
    }
  }, [userId]);

  const navigate = useNavigate();

  const handleUserClick = (userId) => {
    navigate(`/user/${userId}`);
    console.log(`User with ID ${userId} clicked!`);
  };

  const maxLeagueNameWidth = 300; // Set your preferred max width here
  const fontSize = `clamp(1rem, 2vw, ${
    (maxLeagueNameWidth / userLeagues.length) * 2
  }rem)`; // Adjust font size based on league name length

  return (
    <Container>
      <Row
        justify="center"
        align="middle"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <UserIdForLeagues
          userId={userId}
          handleUserIdChange={handleUserIdChange}
        />
      </Row>
      <Row justify="center" gap={2}>
        {userId ? (
          userLeagues.map((league) => (
            <Card
              style={{
                paddingLeft: "2rem",
                paddingRight: "2rem",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              key={league.league_id}
            >
              <Card.Body>
                <LeagueName
                  league={league}
                  style={{
                    maxWidth: maxLeagueNameWidth,
                    fontSize: fontSize,
                    whiteSpace: "wrap",
                    overflow: "hidden",
                    textOverflow: "hidden",
                  }}
                />
                {league.users.map((user) => (
                  <Button
                    key={user.user_id}
                    onPress={() => handleUserClick(user.user_id)}
                    style={{ margin: "0.5rem" }}
                  >
                    {user.display_name}
                  </Button>
                ))}
              </Card.Body>
            </Card>
          ))
        ) : (
          <p>Please enter your username or user ID above.</p>
        )}
      </Row>
    </Container>
  );
}

export default MyLeagues;
