import React, { useState, useEffect } from "react";
import { Card, Text } from "@nextui-org/react";
import Player from "./Player";

function LeagueRosters({ userLeagues, userId }) {
  const [userRosters, setUserRosters] = useState([]);

  useEffect(() => {
    const fetchRosters = async () => {
      const rosters = await Promise.all(
        userLeagues.map((league) =>
          fetch(`/api/rosters/${league.league_id}`)
            .then((response) => response.json())
            .then((data) => data)
        )
      );
      setUserRosters(rosters);
    };
    if (userLeagues.length) {
      fetchRosters();
    }
  }, [userLeagues]);

  const renderRoster = (leagueId) => {
    const roster = userRosters.find((roster) =>
      roster.find((r) => r.owner_id === userId && r.league_id === leagueId)
    );
    if (roster) {
      return roster.find(
        (r) => r.owner_id === userId && r.league_id === leagueId
      );
    }
    return null;
  };

  return (
    <div style={{ display: "flex" }}>
      {userLeagues.map((league, index) => {
        const myRoster = renderRoster(league.league_id);

        return (
          <Card
            key={league.league_id}
            variant="bordered"
            css={{ mw: "400px", my: "2rem", bg: "$blue1", color: "$blue11" }}
          >
            {myRoster && (
              <>
                <Card.Body css={{ py: "$3", px: "$4" }}>
                  <Text h2 align="center" css={{ mb: "$2" }}>
                    Starting Lineup:
                  </Text>
                  <ul className="starters" css={{ pl: 0 }}>
                    {myRoster.starters.map((playerId) => (
                      <li
                        align="center"
                        className="starting-player"
                        key={playerId}
                      >
                        <Player playerId={playerId} />
                      </li>
                      // TODO: Change List element to PlayerList.js
                    ))}
                  </ul>
                  <Text h3 align="center" css={{ mb: "$2", mt: "$4" }}>
                    Bench:
                  </Text>
                  <ul className="bench" css={{ pl: 0 }}>
                    {myRoster.players
                      .filter(
                        (playerId) => !myRoster.starters.includes(playerId)
                      )
                      .map((playerId) => (
                        <li
                          align="center"
                          className="bench-player"
                          key={playerId}
                        >
                          <Player playerId={playerId} />
                        </li>
                      ))}
                  </ul>
                </Card.Body>
                <Card.Footer
                  css={{ bg: "$blue9", color: "$blue1", py: "$2", px: "$4" }}
                >
                  {/* Empty footer */}
                </Card.Footer>
              </>
            )}
          </Card>
        );
      })}
    </div>
  );
}
export default LeagueRosters;
