import React, { useState } from "react";
import { Input, Button, Row } from "@nextui-org/react";

function LeagueId({ handleLeagueIdChange }) {
  const [inputValue, setInputValue] = useState("");

  const handleClick = () => {
    if (inputValue.trim() !== "") {
      handleLeagueIdChange(inputValue);
    }
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <Row justify="center" align="middle">
      <Input
        id="leagueIdInput"
        type="text"
        value={inputValue}
        onChange={handleChange}
        bordered
        labelPlaceholder="Enter the League ID here."
        color="primary"
        width="17rem"
      />
      <Button
        onPress={handleClick}
        shadow
        color="primary"
        auto
        style={{ marginLeft: "1rem" }}
      >
        Submit
      </Button>
    </Row>
  );
}

export default LeagueId;
