import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Text, Grid, Container } from "@nextui-org/react";
import LeagueName from "./LeagueName";

function UserDetails() {
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [userRosters, setUserRosters] = useState(null);
  const [leagueData, setLeagueData] = useState(null);
  const [leagueDetails, setLeagueDetails] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userResponse = await fetch(`/api/user/${id}`);
        const userData = await userResponse.json();
        setUserData(userData);

        const leaguesResponse = await fetch(`/api/user-leagues/${id}`);
        const leaguesData = await leaguesResponse.json();
        setLeagueData(leaguesData);

        // get user rosters for each league
        const rosters = await Promise.all(
          leaguesData.map((league) =>
            fetch(`/api/rosters/${league.league_id}`)
              .then((response) => response.json())
              .then((data) => data)
          )
        );
        setUserRosters(rosters);

        // get league info and details for each league
        const leagueInfoDetails = await Promise.all(
          leaguesData.map((league) =>
            fetch(`/api/league/${league.league_id}`)
              .then((response) => response.json())
              .then((data) => ({ leagueId: league.league_id, data }))
          )
        );

        // create an object with league_id as key and league info/details as value
        const leagueInfoDetailsObj = leagueInfoDetails.reduce((acc, cur) => {
          const { leagueId, data } = cur;
          acc[leagueId] = data;
          return acc;
        }, {});

        setLeagueDetails(leagueInfoDetailsObj);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [id]);

  const renderRoster = (leagueId) => {
    if (userRosters && userRosters.length > 0) {
      const roster = userRosters.find((roster) =>
        roster.find((r) => r.owner_id === id && r.league_id === leagueId)
      );
      if (roster) {
        return roster.find(
          (r) => r.owner_id === id && r.league_id === leagueId
        );
      }
    }
    return null;
  };

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  return (
    <Container css={{ display: "flex", justifyContent: "center" }}>
      <Card
        css={{
          p: "$6",
          mw: "800px",
        }}
      >
        <Card.Header css={{ mx: "$72" }}>
          <img
            alt="avatar"
            src={`https://sleepercdn.com/avatars/thumbs/${userData.avatar}`}
            width="50px"
            height="50px"
            style={{ borderRadius: "50%" }}
          />
          <Grid.Container css={{ pl: "$6" }}>
            <Grid xs={12}>
              <Text h2 css={{ lineHeight: "$xs" }}>
                {userData.display_name}
              </Text>
            </Grid>
          </Grid.Container>
        </Card.Header>
        <Card.Divider />
        <Grid.Container
          gap={2}
          css={{ justifyContent: "center", maxWidth: "800px" }}
        >
          <Grid xs={12} md={12} lg={12} xl={12}>
            <Card>
              <Card.Header>
                <Text
                  h4
                  css={{
                    lineHeight: "$xs",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  Leagues
                </Text>
              </Card.Header>
              <Card.Body>
                {leagueData && leagueData.length > 0 ? (
                  leagueData.map((league) => (
                    <div style={{ margin: "0 auto" }} key={league.league_id}>
                      <Container>
                        <LeagueName league={league} css={{ width: "100%" }} />
                      </Container>
                      <div style={{ margin: "0 auto", textAlign: "center" }}>
                        {leagueDetails && leagueDetails[league.league_id] && (
                          <Container>
                            <Text>{`Season: ${
                              leagueDetails[league.league_id].season
                            }`}</Text>

                            <Text>{`Scoring: ${
                              leagueDetails[league.league_id].scoring_settings
                                .rec === 1
                                ? "PPR"
                                : "Standard"
                            }
                            `}</Text>

                            <Text>{`Draft Type: ${
                              leagueDetails[league.league_id].settings
                                .draft_rounds
                            } Round Draft`}</Text>
                          </Container>
                        )}
                        {renderRoster(league.league_id) ? (
                          <Container>
                            <Text>{`Record: ${
                              renderRoster(league.league_id).settings.wins
                            }-${
                              renderRoster(league.league_id).settings.losses
                            }-${
                              renderRoster(league.league_id).settings.ties
                            }`}</Text>
                            <br />
                          </Container>
                        ) : (
                          <div>No roster found</div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No leagues found</div>
                )}
              </Card.Body>
            </Card>
          </Grid>
        </Grid.Container>
      </Card>
    </Container>
  );
}

export default UserDetails;
