import { useState, useEffect } from "react";
import LeagueRosters from "./LeagueRosters";
import LeagueName from "./LeagueName";
import { Card, Collapse, Grid } from "@nextui-org/react";

function UserLeagues({ userId }) {
  const [userLeagues, setUserLeagues] = useState([]);

  useEffect(() => {
    const fetchUserLeagues = async () => {
      try {
        const response = await fetch(`/api/user-leagues/${userId}`);
        const data = await response.json();
        setUserLeagues(data);
      } catch (error) {
        console.error("Error fetching user leagues:", error);
      }
    };
    fetchUserLeagues();
  }, [userId]);

  const renderRoster = (league, leagueId) => {
    if (league.rosters) {
      const roster = league.rosters.find((roster) =>
        roster.find((r) => r.owner_id === userId && r.league_id === leagueId)
      );
      if (roster) {
        return roster.find(
          (r) => r.owner_id === userId && r.league_id === leagueId
        );
      }
    }
    return null;
  };

  return (
    <Grid.Container gap={3}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {userLeagues.map((league, index) => {
          const myRoster = renderRoster(league.league_id);
          return (
            <Grid xs={12} sm={6} md={4} key={league.league_id}>
              <div style={{ width: "100%" }}>
                <MockItem league={league} myRoster={myRoster} userId={userId} />
              </div>
            </Grid>
          );
        })}
      </div>
    </Grid.Container>
  );
}

const MockItem = ({ league, myRoster, userId }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(false);
  }, [league]);

  const toggle = () => setExpanded(!expanded);

  const maxLeagueNameWidth = 300; // Set your preferred max width here
  const fontSize = `clamp(1rem, 2vw, ${
    (maxLeagueNameWidth / league.name.length) * 2
  }rem)`; // Adjust font size based on league name length

  return (
    <Card bordered="true" shadow="true" style={{ width: "400px" }}>
      <Collapse
        title={
          <Card.Header align="center" onClick={toggle}>
            <LeagueName
              league={league}
              style={{
                maxWidth: maxLeagueNameWidth,
                fontSize: fontSize,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
          </Card.Header>
        }
        expanded={expanded}
        onToggle={toggle}
        splitted="true"
        shadow="true"
      >
        {expanded && (
          <Card.Body align="center">
            <LeagueRosters
              userLeagues={[league]}
              userId={userId}
              myRoster={myRoster}
            />
          </Card.Body>
        )}
      </Collapse>
    </Card>
  );
};

export default UserLeagues;
