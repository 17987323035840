import { useState, useEffect } from "react";
import LeagueName from "./LeagueName";
import { Container } from "@nextui-org/react";
import LeagueDetails from "./LeagueDetails";

function GetLeague({ leagueId }) {
  const [league, setLeague] = useState(null);
  const [leagueDetails, setLeagueDetails] = useState(null);

  useEffect(() => {
    const fetchLeagueInfo = async () => {
      try {
        const response = await fetch(`/api/league/${leagueId}`);
        const data = await response.json();
        setLeague(data);
      } catch (error) {
        console.error("Error fetching league info:", error);
      }
    };

    if (leagueId) {
      fetchLeagueInfo();
    } else {
      setLeague(null);
    }
  }, [leagueId]);

  useEffect(() => {
    const fetchLeagueDetails = async () => {
      try {
        const response = await fetch(`/api/league/${leagueId}/users`);
        const data = await response.json();
        setLeagueDetails(data);
      } catch (error) {
        console.error("Error fetching league details:", error);
      }
    };

    if (leagueId) {
      fetchLeagueDetails();
    } else {
      setLeagueDetails(null);
    }
  }, [leagueId]);

  const maxLeagueNameWidth = 300; // Set your preferred max width here
  const fontSize = league
    ? `clamp(1rem, 2vw, ${(maxLeagueNameWidth / league.name.length) * 2}rem)`
    : "";

  return (
    <>
      {league && leagueDetails && (
        <Container style={{ display: "flex", justifyContent: "center" }}>
          <LeagueName
            league={league}
            style={{
              maxWidth: maxLeagueNameWidth,
              fontSize: fontSize,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />
          <LeagueDetails league={league} leagueDetails={leagueDetails} />
        </Container>
      )}
    </>
  );
}

export default GetLeague;
