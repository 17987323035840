import { Container, Text } from "@nextui-org/react";

function LeagueName({ league, style }) {
  const maxLeagueNameWidth = style?.maxWidth || 300;
  const fontSize =
    style?.fontSize ||
    `clamp(1rem, 2vw, ${(maxLeagueNameWidth / league.name.length) * 2}rem)`;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Container>
        <div style={{ height: "75px" }}>
          <Text
            h2
            weight="bold"
            align="center"
            color="primary"
            style={{
              maxWidth: maxLeagueNameWidth,
              fontSize: fontSize,
              whiteSpace: style?.whiteSpace || "nowrap",
              overflow: style?.overflow || "hidden",
              textOverflow: style?.textOverflow || "ellipsis",
            }}
          >
            {league.name}
          </Text>
        </div>
        <br />

        <Text align="center">
          {league.total_rosters} Team League (
          {league.scoring_settings.rec === 1 ? "PPR" : "Standard"})
        </Text>
      </Container>
    </div>
  );
}

export default LeagueName;
