import LeagueId from "./LeagueId";
import GetLeague from "./GetLeague";
import { Row, Col, Container } from "@nextui-org/react";

function SearchLeague({ leagueId, handleLeagueIdChange }) {
  return (
    <Container>
      <Row
        justify="center"
        align="middle"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <LeagueId
          leagueId={leagueId}
          handleLeagueIdChange={handleLeagueIdChange}
        />
      </Row>
      <Row justify="center">
        {leagueId ? (
          <Col span={12}>
            <GetLeague leagueId={leagueId} />
          </Col>
        ) : (
          <p>Please enter a Sleeper League ID above.</p>
        )}
      </Row>
    </Container>
  );
}

export default SearchLeague;
